import { Button } from "../../../components/Buttons";
import { headerList } from "../../../utils/constant";
import { useModal } from "../../../contexts/ModalContext";
import { useNavigate, useParams } from "react-router-dom";
import CallServiceTable from "../../../components/Tables/CallServiceTable";
import { useDialog } from "../../../contexts/DialogContext";
import GenerateRoadmap from "../../../containers/ManegeProcedure/GenerateRoadmap";
import { ImportClearancePdf } from "../../../containers/ManegeProcedure/ImportClearancePdf";
import { Request } from "../../../hooks/Request";
import { Circle } from "../../../components/Animation";
import * as dayjs from "dayjs";
import { toast } from "react-toastify";
import { useState } from "react";
import { requestAuth } from "../../../components/services/RequestService";
import { H3 } from "../../../components/Text";
import { DialogConfirmation } from "../../../components/Modal";
import { useAuth } from "../../../contexts/AuthContext";


const FolderListObservation = () => {
  const { rolId } = useParams();
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { openDialog } = useDialog();


  function handleFolderStep(procedure) {
    navigate(`/rol/${rolId}/menu/folderList/step-procedure/${procedure.id}`);
  }

  function handleObservation(procedure, reload) {
    openModal(
      <ObservationList procedure={procedure} reload={reload}/>
    )
  }

  function generateRoadmap(procedure) {
    openDialog(<GenerateRoadmap procedure={procedure} />);
  }

  function generateLiquidation(procedure) {
    openDialog(<ImportClearancePdf procedure={procedure} />);
  }

  return (
    <CallServiceTable
      
      // onClick={handleFolderStep}
      filters={[
        {
          name: "procedureType",
          url: "/procedureType",
          default: "Todos los tramites",
          valueOption: "id",
          labelOption: "name",
        },
        {
          name: "companyId",
          url: "/company/getallcompanies",
          default: "Todas las compañías",
          valueOption: "id",
          labelOption: "razonSocial",
        },
        {
          name: "Year",
          default: "Todas las gestiones",
          options: [
            {
              label: "2024",
              name: "2024",
              initial: true,
            },
            {
              label: "2023",
              name: "2023",
            },
            {
              label: "2022",
              name: "2022",
            },
            {
              label: "2021",
              name: "2021",
            },
            {
              label: "2020",
              name: "2020",
            },
            {
              label: "2019",
              name: "2019",
            },
            {
              label: "2018",
              name: "2018",
            },
            {
              label: "2017",
              name: "2017",
            },
            {
              label: "2016",
              name: "2016",
            },
            {
              label: "2015",
              name: "2015",
            },
          ],
        },
        {
          name: "gestor",
          url: "/Dropdown/rol_gestor/options",
          label: "Todos los gestores",
          valueOption: "name",
          labelOption: "name",
        },
        {
          name: "EstadoDeLaCarpeta",
          default: "Todas las carpetas",
          label: "Todas las carpetas",
          options: [
            {
              label: "Observado",
              name: "Observado",
            },
            {
              label: "Subsanado",
              name: "Subsanado",
            },
            {
              label: "Obs. por Archivo",
              name: "Obs. por Archivo",
            },
            {
              label: "Sub. para Archivo",
              name: "Sub. para Archivo",
            },
            {
              label: "Entregado",
              name: "Entregado",
            },
          ],
        },
      ]}
      urlApi="/procedure/openprocedures"
      addFilters={[
        {
          name: "internCode",
          label: "Nro. interno",
          filter: true,
        },
        {
          name: "dimNumber",
          label: "Nro. DIM/DEX",
          filter: true,
        },
      ]}
      header={[
        {
          label: "Estado",
          name: "estadoDeLaCarpeta",
          type: "custom",
          builder: (state, a, pro) => {

            if(state===null){
              return  <div className="relative inline-block">
                        <span className="block w-fit px-2 py-1 rounded-full text-white bg-blue-800">
                          Creado
                        </span>
                      </div>
            }

            const background=state==="Observado"
            ? "bg-orange-600 animate-pulse"
            : state==="Subsanado"
            ? "bg-emerald-500"
            : state==="Obs. por Archivo"
            ? "bg-red-600 animate-pulse"
            : state==="Sub. para Archivo"
            ? "bg-teal-600"
            : state==="Entregado"
            ? "bg-green-600"
            : ""
            
            return (
              <div className="relative inline-block">
                <span className={`block w-fit px-2 py-1 whitespace-nowrap rounded-full text-white ${background}`}>
                  {state}
                </span>
                {
                  state==="Observado" || state==="Obs. por Archivo"
                  ? <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 border border-red-600 bg-white text-red-600 text-xs font-bold rounded-full px-1 flex items-center justify-center ">
                      {dayjs().diff(dayjs(pro.obsCreationDate), "day")!==0?dayjs().diff(dayjs(pro.obsCreationDate), "day")+" días":dayjs().diff(dayjs(pro.obsCreationDate), "hour")!==0?dayjs().diff(dayjs(pro.obsCreationDate), "hour")+" horas":dayjs().diff(dayjs(pro.obsCreationDate), "minute")+" minutos"} 
                    </span>
                  : ""
                }
                
              </div>

            );
          },
        },
        {
          label: "Gestor Asignado",
          name: "asignGestor",
        },
        ...headerList,
        {
          name: "Reports",
          type: "action",
          label: "Reportes",
          sticky: true,
          actions: [
            {
              label: "Observaciones",
              icon: "fas fa-eye",
              action: handleObservation,
              color: "text-[#E95793]",
            },
            {
              label: "Lista de Pasos",
              icon: "fas fa-shoe-prints",
              action: handleFolderStep,
              color: "text-[#1d4ed8]",
            },
            {
              label: "Reporte de liquidación",
              icon: "fas fa-print",
              action: generateLiquidation,
              color: "text-[#1d4ed8]",
            },
            {
              label: "Generar hoja de ruta",
              icon: "fas fa-route",
              action: generateRoadmap,
              color: "text-[#1d4ed8]",
            },
          ],
        },
      ]}
    />
  );
};

export default FolderListObservation;

const ObservationList = ({procedure, reload}) => {

  const { data, loading } = Request({
    urlApi: `/Procedure/${procedure.id}/datasets?fieldnames=observacionesDeLasCarpeta&fieldnames=estadoDeLaCarpeta&fieldnames=asignarGestor`,
  });

  if(!loading){
    return <Circle/>
  }  


  return (
    <Observations fields={data} procedure={procedure} reload={reload}/>
  )
}

const Observations = ({fields, procedure, reload}) => {
  const { data, call, loading } = Request({
    urlApi: `/dataSet/procedure/${procedure.id}/field/${fields[1].id}/subdata`,
  });

  const { user } = useAuth();


  const [state, setState] = useState(procedure.estadoDeLaCarpeta)
  
  const { openDialog, dialogClose } = useDialog();

  if(!loading){
    return <Circle/>
  }

  const obsGestor = data.filter(item => item.label === 'Observaciones del Gestor');
  const obsArchivo = data.filter(item => item.label === 'Observaciones de Archivo');

  if(state==="verificar"){
    changeStateSubsanado();
  }

  async function changeStateSubsanado() {

    if(obsArchivo?.some(item => item.description4 === 'Observado')){
      const formDataState=[
        {
          value: "Obs. por Archivo",
          procedureId: procedure.id,
          fieldId: fields[2].id,
        },
      ]
      await requestAuth("POST", `/dataSet`, formDataState)
      .then((res) => {
        // toast.success("Se guardaron los cambios");
        reload();  
        setState(procedure.estadoDeLaCarpeta)
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
    }else if(obsArchivo?.some(item => item.description4 === 'Subsanado')){
      const formDataState=[
        {
          value: "Sub. para Archivo",
          procedureId: procedure.id,
          fieldId: fields[2].id,
        }        
      ]
      await requestAuth("POST", `/dataSet`, formDataState)
      .then((res) => {
        // toast.success("Se guardaron los cambios");
        reload();  
        setState(procedure.estadoDeLaCarpeta)
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
    }else if(obsGestor?.some(item => item.description4 === 'Observado')){
      const formDataState=[
        {
          value: "Observado",
          procedureId: procedure.id,
          fieldId: fields[2].id,
        },
      ]
      await requestAuth("POST", `/dataSet`, formDataState)
      .then((res) => {
        // toast.success("Se guardaron los cambios");
        reload();  
        setState(procedure.estadoDeLaCarpeta)
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
    }else if(obsGestor?.some(item => item.description4 === 'Subsanado')){
      const formDataState=[
        {
          value: "Subsanado",
          procedureId: procedure.id,
          fieldId: fields[2].id,
        },
      ]
      await requestAuth("POST", `/dataSet`, formDataState)
      .then((res) => {
        // toast.success("Se guardaron los cambios");
        reload();  
        setState(procedure.estadoDeLaCarpeta)
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
    }else{
      const formDataState=[
        {
          value: null,
          procedureId: procedure.id,
          fieldId: fields[2].id,
        },
      ]
      await requestAuth("POST", `/dataSet`, formDataState)
      .then((res) => {
        // toast.success("Se guardaron los cambios");
        reload();  
        setState(procedure.estadoDeLaCarpeta)
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
    }
    
    
    
  }

  function addObservation(obs) {
    openDialog(
      <InputObservation type="Observaciones del Gestor" dialogClose={ dialogClose } reload={reload} obs={obs} procedureId={ procedure.id } fieldIdState={fields[2].id} fieldId={fields[1].id} call={call} />
    );
  }
  function addObservationArchive(obs) {
    openDialog(
      <InputObservation type="Observaciones de Archivo" dialogClose={ dialogClose } reload={reload} obs={obs} procedureId={ procedure.id } fieldIdState={fields[2].id} fieldId={fields[1].id} call={call} />
    );
  }

  function deleteObservation(obs) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Esta seguro de eliminar la observación?</H3>}
        onClose={dialogClose}
        method="DELETE"
        url={`/dataSet/procedure/subData/${obs.id}`}
        texBtn="Eliminar"
        handleSuccess={refresh}
      />
    );
  }

  function correctObservation(obs) {

    const formData = {
      ...obs,
      description4: "Subsanado",
    }
  
    openDialog(
      <DialogConfirmation
        children={<H3>¿Esta seguro que subsano la observación?</H3>}
        onClose={dialogClose}
        method="PUT"
        url={`/dataSet/procedure/subData/${obs.id}`}
        texBtn="Subsanar"
        handleSuccess={refresh}
        data={formData}
      />
    );
  }

  async function refresh(){
    reload()
    call()
    setState("verificar")
    call()
    
  }

  return (
    <div className="p-5">
      <div className="flex gap-5 justify-center">
        <h2 className="text-2xl font-bold">Observaciones</h2>
      </div>
      {
        // user.rols
        user.rols?.some(item => item.name === "Gestor")
        ? procedure.estadoDeLaCarpeta==="Obs. por Archivo" || procedure.estadoDeLaCarpeta==="Entregado" || procedure.estadoDeLaCarpeta==="Sub. para Archivo" || procedure.asignGestor!==`${user.user.name} ${user.user.fatherLastName} ${user.user.motherLastName}`
          ? " "
          : <Button className="py-2 w-full mt-2" onClick={() => addObservation()}>
              Añadir obsevación
            </Button>
        : user.rols?.some(item => item.name === "archivo") && (procedure.estadoDeLaCarpeta==="Entregado" || procedure.estadoDeLaCarpeta==="Sub. para Archivo" || procedure.estadoDeLaCarpeta==="Obs. por Archivo")
          ? <Button className="py-2 w-full mt-2" onClick={() => addObservationArchive()}>
              Añadir obsevación
            </Button>
          : " "
        
      }
      
      { obsArchivo.length===0 ? "" : <h4 className="text-lg my-2">Observaciones de Archivo</h4>}


      {
        obsArchivo?.map(obs=>(
          obs.description4==="Observado"
          ? <div key={obs.id} className="bg-white rounded-md px-5 py-2 mb-2">
              <div                  
                className="flex gap-2 justify-between items-center"
              >
                <div className="text-xs text-slate-500">
                  <p>Observado por: {obs.description3}</p>
                  <p>En fecha: {dayjs(obs.creationDate).format("DD/MM/YYYY HH:mm")}</p>
                </div>         
                <div>
                  <p className="text-red-500 text-center font-bold">Observado</p>
                  <div className="flex justify-center gap-1">                                       
                    {
                      procedure.asignGestor===`${user.user.name} ${user.user.fatherLastName} ${user.user.motherLastName}` && user.rols?.some(item => item.name === "Gestor")
                      ? <button onClick={() => correctObservation(obs)}  className="block bg-teal-500 hover:bg-teal-600 text-white text-sm rounded-full px-2 py-1">
                          <i className="fa-solid fa-check text-white mr-2"></i>
                          Subsanar
                        </button>
                      : ""
                    }
                    {
                      user.rols?.some(item => item.name === "archivo")
                      ? <>
                          <button onClick={() => addObservationArchive(obs)} className="block bg-[#F3B303] hover:bg-[#ECC759] text-white text-sm rounded-full px-2 py-1">
                            <i className="fas fa-pencil text-white mr-2"></i>
                            Editar
                          </button>  
                          <button onClick={() => deleteObservation(obs)} className="block bg-red-500 hover:bg-red-600 text-white text-sm rounded-full px-2 py-1">
                            <i className="fas fa-trash text-white mr-2"></i>
                            Eliminar
                          </button></>
                      : " "
                    }
                  </div>
                </div>
              </div>
              <div className="p-5 bg-red-300 border border-red-600 rounded-lg mt-5">
                <p>{obs.description}</p>
              </div>
            </div>
          : <div key={obs.id} className="bg-white rounded-md px-5 py-2 mb-2">
              <div                  
                className="flex gap-2 justify-between items-center"
              >   
                <div className="text-xs text-slate-500">
                  <p>Observado por: {obs.description3}</p>
                  <p>En fecha: {dayjs(obs.creationDate).format("DD/MM/YYYY HH:mm")}</p>
                </div>     
                <div>
                  <p className="text-teal-500 text-center font-bold">Subsanado</p>
                  {/* <button onClick={() => deleteObservation(obs)} className="block bg-red-500 hover:bg-red-600 text-white text-sm rounded-full px-2 py-1">
                      <i className="fas fa-trash text-white mr-2"></i>
                      Eliminar
                    </button>  */}
                </div>
              </div>
              <div className="px-5 pt-5 pb-2 bg-teal-300 border border-teal-600 rounded-lg mt-5">
                <p>{obs.description}</p>
                <div className="mt-2 flex justify-end">
                  <div className="text-xs text-slate-500">
                    <p>Subsanado por: {obs.description2}</p>
                    <p>En fecha: {dayjs(obs.endDate).format("DD/MM/YYYY HH:mm")}</p>
                  </div>
                </div>
              </div>
            </div>
        ))
      }

      

      
      {/* GESTOR */}
      { obsGestor.length===0 ? "" : <h4 className="text-lg my-2">Observaciones del Gestor</h4>}
      {
        obsGestor?.map(obs=>(
          obs.description4==="Observado"
            ? <div key={obs.id} className="bg-white rounded-md px-5 py-2 mb-2">
                <div                  
                  className="flex gap-2 justify-between items-center"
                >
                  <div className="text-xs text-slate-500">
                    <p>Observado por: {obs.description3}</p>
                    <p>En fecha: {dayjs(obs.creationDate).format("DD/MM/YYYY HH:mm")}</p>
                  </div>         
                  <div>
                    <p className="text-orange-500 text-center font-bold">Observado</p>
                    <div className="flex justify-center gap-1">
                      {
                        procedure.asignGestor===`${user.user.name} ${user.user.fatherLastName} ${user.user.motherLastName}`
                        ? <>
                            <button onClick={() => addObservation(obs)} className="block bg-[#F3B303] hover:bg-[#ECC759] text-white text-sm rounded-full px-2 py-1">
                              <i className="fas fa-pencil text-white mr-2"></i>
                              Editar
                            </button>             
                            <button onClick={() => correctObservation(obs)} className="block bg-teal-500 hover:bg-teal-600 text-white text-sm rounded-full px-2 py-1">
                              <i className="fa-solid fa-check text-white mr-2"></i>
                              Subsanar
                            </button>              
                            <button onClick={() => deleteObservation(obs)} className="block bg-red-500 hover:bg-red-600 text-white text-sm rounded-full px-2 py-1">
                              <i className="fas fa-trash text-white mr-2"></i>
                              Eliminar
                            </button>
                          </> 
                        : " "
                      }             
                    </div>
                  </div>
                </div>
                <div className="p-5 bg-orange-300 border border-orange-600 rounded-lg mt-5">
                  <p>{obs.description}</p>
                </div>
              </div>
            : <div key={obs.id} className="bg-white rounded-md px-5 py-2 mb-2">
                <div                  
                  className="flex gap-2 justify-between items-center"
                >   
                  <div className="text-xs text-slate-500">
                    <p>Observado por: {obs.description3}</p>
                    <p>En fecha: {dayjs(obs.creationDate).format("DD/MM/YYYY HH:mm")}</p>
                  </div>     
                  <div>
                    <p className="text-teal-500 text-center font-bold">Subsanado</p>
                    {/* <button onClick={() => deleteObservation(obs)} className="block bg-red-500 hover:bg-red-600 text-white text-sm rounded-full px-2 py-1">
                      <i className="fas fa-trash text-white mr-2"></i>
                      Eliminar
                    </button>  */}
                  </div>
                </div>
                <div className="px-5 pt-5 pb-2 bg-teal-300 border border-teal-600 rounded-lg mt-5">
                  <p>{obs.description}</p>
                  <div className="mt-2 flex justify-end">
                    <div className="text-xs text-slate-500">
                      <p>Subsanado por: {obs.description2}</p>
                      <p>En fecha: {dayjs(obs.endDate).format("DD/MM/YYYY HH:mm")}</p>
                    </div>
                  </div>
                </div>
              </div>
        ))
      }
      
      </div>
      
  )
}


const InputObservation = ({ type, dialogClose, procedureId, fieldId, fieldIdState, call, obs, reload }) => {

  const [inputValue, setInputValue] = useState(obs?.description || "");


  const inputChange = ( {target} ) => {
    setInputValue(target.value);
  }

  async function addObservation() {

    if(inputValue.trim().length===0) return;

    if(obs){
      const formData = {
        ...obs,
        label: type,
        description: inputValue.trim(),
      }
  
      await requestAuth("PUT", `/dataSet/procedure/subdata/${obs.id}`, formData)
        .then((res) => {
          toast.success("Se guardo la observación");
          dialogClose();
          call();   
        })
        .catch(() => {
          toast.error("Se produjo un error al actualizar la observación");
        });
    }else{

      const formData = {
        label: type,
        description: inputValue.trim(),
      }
  
      await requestAuth("POST", `/dataSet/procedure/${procedureId}/field/${fieldId}/subdata`, formData)
        .then((res) => {
          // toast.success("Se guardo la observación");
          // dialogClose();
          // call();   
        })
        .catch((err) => {
          toast.error("Se produjo un error al añadir observación");
        });

        const formDataState=[
          {
            value: type==="Observaciones del Gestor"?"Observado":"Obs. por Archivo",
            procedureId: procedureId,
            fieldId: fieldIdState,
          },
          
        ]
        await requestAuth("POST", `/dataSet`, formDataState)
        .then((res) => {
          toast.success("Se guardo la observación");
          dialogClose();
          call(); 
          reload();  
        })
        .catch(() => {
          toast.error("Se produjo un error al añadir observación");
        });
    }

    

    
  }

  return (
    <>
      <h4 className="text-center text-xl font-bold">Observaciones</h4>
      <input 
        type="text"
        className="w-full bg-white border border-gray-300 px-3 py-2 rounded-md outline-none caret-blue-600 focus:border-blue-600 my-3" 
        placeholder="Sin observaciones"
        value={ inputValue }
        onChange={ inputChange }
      />
      <div className="w-96 flex gap-4 m-4">        
          <>
            <Button
            onClick={() => dialogClose()}
              className="w-full bg-[#fff] text-[#E7641D] border-2 border-[#E7641D]"
            >
              Cancelar
            </Button>
            <Button 
              onClick={() => addObservation()}
              className="w-full bg-[#E7641D]">
              Guardar
            </Button>
          </>       
      </div>
    </>
  )
}


