import { Button } from "../../../components/Buttons";
import { headerList } from "../../../utils/constant";
import { NewProcedure } from "../../../containers/steps";
import { useModal } from "../../../contexts/ModalContext";
import { useNavigate, useParams } from "react-router-dom";
import CallServiceTable from "../../../components/Tables/CallServiceTable";
import { useDialog } from "../../../contexts/DialogContext";
import GenerateRoadmap from "../../../containers/ManegeProcedure/GenerateRoadmap";
import { ImportClearancePdf } from "../../../containers/ManegeProcedure/ImportClearancePdf";
import { ImportProcedureExcel } from "../../../containers/ManegeProcedure/ImportProcedureExcel";
import { ExportProcedureExcel } from "../../../containers/ManegeProcedure/ExportProcedureExcel";

const FolderList = () => {
  const { rolId } = useParams();
  const navigate = useNavigate();
  const { openModal, onClose } = useModal();
  const { openDialog, dialogClose } = useDialog();

  function handleAdd(reload) {
    openModal(<NewProcedure reload={reload} onClose={onClose} />);
  }

  function handleFolderStep(procedure) {
    navigate(`/rol/${rolId}/menu/folderList/step-procedure/${procedure.id}`);
  }

  function generateRoadmap(procedure) {
    openDialog(<GenerateRoadmap procedure={procedure} />);
  }

  function generateLiquidation(procedure) {
    openDialog(<ImportClearancePdf procedure={procedure} />);
  }

  function generateImportItems() {
    // openDialog(
    //   <ExportStatementAduana
    //     companyId={user?.company[0]?.id}
    //     openDialog={openDialog}
    //   />
    // );
    openDialog(<ImportProcedureExcel openDialog={openDialog} />);
  }

  function generateExportItems() {
    openDialog(<ExportProcedureExcel openDialog={openDialog} />);
  }

  return (
    <CallServiceTable
      component={({ reload }) => {
        return (
          <>
            <Button
              position="left"
              content="Importar"
              onClick={() => generateImportItems(reload)}
            >
              <i className="fas fa-file-import"></i>
            </Button>
            <Button
              position="left"
              content="Exportar"
              onClick={() => generateExportItems(reload)}
            >
              <i className="fas fa-file-export"></i>
            </Button>
            <Button onClick={() => handleAdd(reload)}>
              Agregar nuevo trámite
            </Button>
          </>
        );
      }}
      // onClick={handleFolderStep}
      filters={[
        {
          name: "procedureType",
          url: "/procedureType",
          default: "Todos los tramites",
          valueOption: "id",
          labelOption: "name",
        },
        {
          name: "companyId",
          url: "/company/getallcompanies",
          default: "Todas las compañías",
          valueOption: "id",
          labelOption: "razonSocial",
        },
        {
          name: "Year",
          default: "Todas las gestiones",
          options: [
            {
              label: "2024",
              name: "2024",
              initial: true,
            },
            {
              label: "2023",
              name: "2023",
            },
            {
              label: "2022",
              name: "2022",
            },
            {
              label: "2021",
              name: "2021",
            },
            {
              label: "2020",
              name: "2020",
            },
            {
              label: "2019",
              name: "2019",
            },
            {
              label: "2018",
              name: "2018",
            },
            {
              label: "2017",
              name: "2017",
            },
            {
              label: "2016",
              name: "2016",
            },
            {
              label: "2015",
              name: "2015",
            },
          ],
        },
        //   {
        //     // url: '/company/getallcompanies',
        //     // name: 'companyId',
        //     // valueOption: 'id',
        //     // labelOption: 'razonSocial',
        //     type: "rangeDate",
        //   },
      ]}
      urlApi="/procedure/openprocedures"
      addFilters={[
        // {
        //   name: "procedureNumber",
        //   label: "Tramite",
        //   filter: true,
        // },
        {
          name: "internCode",
          label: "Nro. interno",
          filter: true,
        },
        {
          name: "dimNumber",
          label: "Nro. DIM/DEX",
          filter: true,
        },
      ]}
      header={[
        ...headerList,
        {
          name: "Reports",
          type: "action",
          label: "Reportes",
          sticky: true,
          actions: [
            {
              label: "Lista de Pasos",
              icon: "fas fa-shoe-prints",
              action: handleFolderStep,
              color: "text-[#1d4ed8]",
            },
            {
              label: "Reporte de liquidación",
              icon: "fas fa-print",
              action: generateLiquidation,
              color: "text-[#1d4ed8]",
            },
            {
              label: "Generar hoja de ruta",
              icon: "fas fa-route",
              action: generateRoadmap,
              color: "text-[#1d4ed8]",
            },
          ],
        },
      ]}
    />
  );
};

export default FolderList;
